// Custom style...

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.font-sans {
    font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/*
 * Dropdown Menu Animation for Bootstrap Navbar
 * https://startbootstrap.com/snippets/animated-navbar-dropdown
 */

// Change this breakpoint if you change the breakpoint of the navbar
@media (min-width: 992px) {
    .animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}

.bg-indigo {
    background-color: #6574cd !important;
}

.h-5 {
    height: 1.25rem !important;
}

.w-5 {
    height: 1.25rem !important;
}

.flex-1 {
    flex: 1 1 0%;
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .container {
        max-width: 1250px;
    }
}

.bg-light {
    background: #f3f4f6 !important;
}


.card-through {
    background-color: rgb(255 255 255 / 90%) !important;
}

.shadow {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%) !important;
}

.shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.form-control, .custom-select {
    //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03) !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #c5e3da;
    background-image: url(../../public/images/check_circle.svg) !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #F64E60 !important;
}
