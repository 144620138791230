

:root {
    --background-color: #fff;
    --border-color: #ccc;
    --text-color: #555;
    --selected-text-color: #f34d11;
    --hover-background-color: #eee;
}

.yearpicker-container {
    position: absolute;
    color: var(--text-color);
    width: 280px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    font-size: 1rem;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--background-color);
    z-index: 10;
    margin-top: 0.2rem;
}

.yearpicker-header {
    display: flex;
    width: 100%;
    height: 2.5rem;
    border-bottom: 1px solid var(--border-color);
    align-items: center;
    justify-content: space-around;
}

.yearpicker-prev,
.yearpicker-next {
    cursor: pointer;
    font-size: 2rem;
}

.yearpicker-prev:hover,
.yearpicker-next:hover {
    color: var(--selected-text-color);
}

.yearpicker-year {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5rem;
}

.yearpicker-items {
    list-style: none;
    padding: 1rem 0.5rem;
    flex: 0 0 33.3%;
    width: 100%;
}

.yearpicker-items:hover {
    background-color: var(--hover-background-color);
    color: var(--selected-text-color);
    cursor: pointer;
}

.yearpicker-items.selected {
    color: var(--selected-text-color);
    font-weight: bolder;
}

.hide {
    display: none;
}

.yearpicker-items.disabled {
    pointer-events: none;
    color: #bbb;
}
