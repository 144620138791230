//
// Override global variables
//

//$primary: #0BB783;
$primary: #F5841F;
//$primary-hover: #04AA77;
$primary-hover: #e25220;
$primary-light: #FAD8C8;
$primary-inverse: #FAD8C8;

$info: #2A337E;
$info-hover: #121B65;
$info-light: #edeff3;

$success: #0BB783;
//$success-hover: #04AA77;
//$success-light: #9ad4c1;
