.top-background {
    background-image: url("../assets/Ihuzo-bg.jpeg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.top-background-2 {
    background-image: url("../assets/Ihuzo-bg.jpeg");
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.left-text {
    font-size: 35px;
    font-weight: lighter;
}

.font-weight-bolder {
    font-weight: bolder;
}

.custom-button {
    border-radius: 50px !important;
    padding: 6px 20px !important;
    background-color: #f34d11 !important;
    border-color: #f34d11 !important;
}

.custom-button-2 {
    border-radius: 50px !important;
    padding: 7px 25px !important;
    background-color: white !important;
    border-color: white !important;
    color: #f34d11 !important;
}

.custom-button:hover {
    background-color: #d23c06 !important;
    border-color: #d23c06 !important;
}


.num-circle {
    border: 7px solid #eeeeee;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    font-size: 40px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f34d11;
}

.circle-bottom-text {
    font-size: 27px;
    margin-top: 5px;
    font-weight: lighter;
}

.low-bottom-container {
    background-image: url("../assets/low_background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 20px;
}


.low-bottom-title {
    color: white;
    padding: 10px;
    font-weight: 700;
    font-size: 28px;
}

.job-item {
    /*border-bottom: 1px solid rgba(255, 255, 255, .3);*/
    color: white;
    font-weight: lighter;
    padding: 5px;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.category-tab{
    font-size: 21px;
    font-weight: 600;
    text-align: center;
}

.category-tab .link{
    display: block;
    margin: 0 2px;
    color: #b1b0b0;
    text-align: center;
    border-bottom: 2px solid #b1b0b0;
}

.job-item:hover, .workshop-item:hover {
    background-color: rgba(255, 255, 255, .3);
    transition: 0.6s ease-in-out all;
}

.job-item img {
    height: 50px;
}

.partner-title {
    font-size: 22px;
    font-weight: 700;
    padding: 25px;
    display: inline-block;
}



.carousel-inner .carousel-item-right.active,
.carousel-inner .carousel-item-next {
    transform: translateX(33.33%);
}

.carousel-inner .carousel-item-left.active,
.carousel-inner .carousel-item-prev {
    transform: translateX(-33.33%)
}

.carousel-inner .carousel-item-right,
.carousel-inner .carousel-item-left{
    transform: translateX(0);
}

.above-footer-container {
    background-color: #000000;
    color: white;
    margin-top: 50px;
}

.quick-links {
    display: inline-block;
}

.quick-links li {
    display: inline-block;
    padding: 0 10px;
}

.quick-links li:not(:first-child) {
    border-left: 1px solid #eeeeee;
}

.social-footer {
    background-color: #232324;
    padding: 10px;
}

.social-footer img {
    height: 30px;
    margin-right: 10px;
}

.i-huzo-text {
    padding: 0 20px;
    border-bottom: 1px solid #eeeeee;
}

.tile-item-info img {
    width: 40px;
}

.tile-item-info {
    padding: 10px;
}

.tile-sub-title {
    font-weight: lighter;
}

.tile-item-info .details-info {
    padding-left: 5px;
}



.partner-asset .img-item {
    text-align: center;
    height: 110px;
    overflow: hidden;
}

.workshop-item {
    color: white;
    padding: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    font-weight: lighter;
    display: block;
}

.workshop-sub-title {
    font-weight: 800 !important;
}

.d-time {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

.force-inner-span span{
    color: #FFFFFF !important;
}

.reminder-badge {
    background-color: white;
    color: #f34d11;
    padding: 3px;
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 6px;
    display: inline-block;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.d-flex {
    display: flex !important;
}

.computer-img {
    width: 100%;
    bottom: -35px;
    position: absolute;
}


.middle-container {
    background-image: url("../assets/line_background.png");
    background-size: auto 100%;
    /*padding-bottom: 50px;*/
}


@media (min-width: 768px) {

    .middle-margin-top {
        margin-top: 130px;
    }

    .curved-line {
        background-image: url("../assets/curved_line.png");
        background-repeat: no-repeat;
        background-size: 200px;
        background-position: 30% 25%;
    }

    .curved-line-2 {
        background-image: url("../assets/curved_line.png");
        background-repeat: no-repeat;
        background-size: 200px;
        background-position: 71.5% 25%;
    }
}

@media (max-width: 767px) {
    .left-text {
        font-size: 30px;
    }

    .partner-asset img {
        width: auto;
        height: 100px;
    }

    .computer-img {
        width: 100%;
        position: static;
        margin-top: 30px;
    }

    .top-background, .low-bottom-container {
        background-size: auto 100%;
    }


}

.top-button {
    margin-top: 10px;
}

.top-button button {
    color: #0e0e0e;
}

.top-button img {
    height: 30px;
}

@media (max-width: 992px) and (min-width: 768px) {
    .curved-line {
        background-image: url("../assets/curved_line.png");
        background-repeat: no-repeat;
        background-size: 120px;
        background-position: 30% 25%;
    }

    .curved-line-2 {
        background-image: url("../assets/curved_line.png");
        background-repeat: no-repeat;
        background-size: 120px;
        background-position: 71.5% 25%;
    }
}

.nav-link:hover {
    color: black !important;
}

.home-nav .nav-link{
    font-size: 1.1rem !important;
    font-weight: 600 !important;
}

@media (min-width: 992px) {
    .navbar-nav .nav-link {
        color: white;
        border-radius: 4px;
    }

    .nav-link:hover {
        background-color: white;
        color: black !important;
        border-radius: 4px;
    }

    .top-button button {
        color: white;
    }


}

@media (min-width: 1200px) {
    .left-text {
        font-size: 60px;
        font-weight: lighter;
    }
}

.my_loader {
    width: 100%;
    height: 100vh !important;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.my_loader .inner {
    width: 100%;
    height: 100vh !important;
    background: url('../../assets/loader.svg') center center no-repeat;
}
